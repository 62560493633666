<template>
  <div class="admin">
    <h4>系统设置</h4>

    <div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'List',
  components: {
  },
  created() {
    this.init();
  },
  data(){
    return {
      list: [],
    }
  },
  methods: {
    init() {

    }

  }

}
</script>